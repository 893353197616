<template>
  <select class="form-select" v-model="selected">
    <option
      v-for="site in sites"
      :key="`site-${site.info.siteid}`"
      :value="site"
    >
      Content on the {{ site.info.sitename }}
    </option>
  </select>
</template>

<script>
import SiteService from "../../../services/SiteService";
export default {
  name: "content.library.site-select",
  data() {
    return {
      sites: [],
      selected: null,
      lsKey: "content.library.site-select.stored-site",
    };
  },
  watch: {
    selected() {
      this.$emit("input", this.selected);
      this.setSelection(this.selected.info.siteid);
    },
  },
  methods: {
    removeSelection() {
      window.localStorage.removeItem(this.lsKey);
    },
    getSelection() {
      return window.localStorage.getItem(this.lsKey);
    },
    setSelection(siteid) {
      window.localStorage.setItem(
        this.lsKey,
        JSON.stringify({
          siteid: siteid,
        })
      );
    },
  },
  async mounted() {
    this.sites = await Promise.all(
      this.$auth.activeCompany.sites.map(async (s) => {
        let siteinfo = await SiteService.getSite(s.siteID);
        s.info = siteinfo;
        return s;
      })
    );
    this.sites.sort((a, b) => a.siteRank - b.siteRank);
    let storedSelection = this.getSelection();
    if (storedSelection) {
      let useSite = JSON.parse(storedSelection);
      try {
        let site = this.sites.filter((s) => s.info.siteid == useSite.siteid)[0];
        if (site) {
          this.selected = site;
        } else {
          this.selected = this.sites[0];
        }
      } catch {
        this.selected = this.sites[0];
      }
    } else {
      this.selected = this.sites[0];
    }
  },
};
</script>
