<template>
  <span
    class="avatar text-black"
    v-b-tooltip.hover
    v-if="contentType"
    :title="contentType.name"
    ref="tooltip"
  >
    <span v-html="contentType.icon"></span>
  </span>
</template>

<script>
import ContentTypeService from "../../../services/ContentTypeService";

export default {
  name: "content.library.content-type-icon",
  props: {
    content: Object,
  },
  data() {
    return {
      contentTypes: [],
      tooltip: null,
    };
  },
  computed: {
    contentType() {
      return this.contentTypes.filter(
        (c) => c.slug == this.content.contentType
      )[0];
    },
  },
  methods: {},
  async mounted() {
    this.contentTypes = await ContentTypeService.getContentTypes();
  },
};
</script>
