import APIService from "./APIService";
import { getInstance } from "../auth/auth";

const http = APIService.http;

export default {
  async getLeads(filter) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post("api/Leads/GetLeads", filter, authHeaders);
    let leads = res.data;
    for (let i = 0; i < leads.length; i++) {
      leads[i].leadSource = auth.mask(leads[i].leadSource, "Lead Source");
      leads[i].leadLastName = auth.mask(leads[i].leadLastName, "Doe");
      leads[i].leadEmailAddress = auth.mask(
        leads[i].leadEmailAddress,
        leads[i].leadFirstName + "@lead.com"
      );
    }
    return leads;
  },
  async getContentLeads(filter) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post("api/Leads/ContentLeads", filter, authHeaders);
    let leads = res.data;
    for (let i = 0; i < leads.length; i++) {
      leads[i].leadSource = parseInt(leads[i].leadSource);
      leads[i].leadLastName = auth.mask(leads[i].leadLastName, "Doe");
      leads[i].leadEmailAddress = auth.mask(
        leads[i].leadEmailAddress,
        leads[i].leadFirstName + "@lead.com"
      );
    }
    return leads;
  },
};
