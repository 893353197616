<template>
  <div class="card content-card">
    <div class="card-header">
      <div>
        <div class="row align-items-center">
          <div class="col-auto">
            <content-type-icon :content="content"></content-type-icon>
          </div>
          <div class="col">
            <div class="card-title">
              <router-link
                :to="`/content/edit?content=${content.whitePaperID}`"
              >
                {{ content.paperTitle }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="card-actions">
        <div class="dropdown z-top">
          <a
            href="#"
            class="btn-action dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            ><!-- Download SVG icon from http://tabler-icons.io/i/dots-vertical -->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <circle cx="12" cy="12" r="1"></circle>
              <circle cx="12" cy="19" r="1"></circle>
              <circle cx="12" cy="5" r="1"></circle>
            </svg>

            <span
              v-if="false"
              class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-2"
              ><span class="visually-hidden">needs to edit</span></span
            >
          </a>
          <div class="dropdown-menu dropdown-menu-end z-top">
            <router-link
              class="dropdown-item"
              :to="`/content/edit?content=${content.whitePaperID}`"
            >
              Edit
            </router-link>
            <a
              class="dropdown-item text-danger"
              href="#"
              @click.prevent="doArchival"
              v-if="archive"
            >
              Archive
            </a>
            <a
              class="dropdown-item text-success"
              href="#"
              @click.prevent="doUnarchival"
              v-else
            >
              Unarchive
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body position-relative p-0">
      <div
        v-if="
          leads.length > 0 && $auth.planIncludes('leads', $auth.highestPlan)
        "
        class="list-group list-group-flush max-height-limit"
      >
        <div class="list-group-header sticky-top">
          {{ leads.length }} {{ pluralLeads }}
        </div>
        <div
          class="list-group-item bg-white"
          v-for="(lead, idx) in leads"
          :key="`content-${content.whitePaperID}-lead-${idx}`"
        >
          <div class="row align-items-center">
            <div class="col text-truncate">
              <a
                :href="`mailto:${lead.leadEmailAddress}`"
                target="_blank"
                class="text-reset d-block"
              >
                {{ lead.leadFirstName }} {{ lead.leadLastName }} -
                {{ lead.leadEmailAddress }}
              </a>
              <div class="d-block text-muted text-truncate mt-n1">
                {{ lead.leadTitle }} @ {{ lead.leadCompanyName }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-else-if="
          leads.length > 0 && !$auth.planIncludes('leads', $auth.highestPlan)
        "
        class="position-relative h-100"
      >
        <div
          class="d-flex flex-column align-items-center justify-content-center position-absolute middle bg-white rounded p-2 border"
        >
          <p class="text-center">
            <strong>
              Your current listing plan does not include lead information.
            </strong>
          </p>

          <div>
            <router-link to="/newupgrade" class="btn btn-primary">
              Upgrade &raquo;
            </router-link>
          </div>
        </div>
        <div class="list-group list-group-flush max-height-limit">
          <div class="list-group-header sticky-top">
            {{ leads.length }} {{ pluralLeads }}
          </div>
          <div
            class="list-group-item bg-white hide"
            v-for="(lead, idx) in leads"
            :key="`hiden-content-${content.whitePaperID}-lead-${idx}`"
          >
            <div class="row align-items-center">
              <div class="col text-truncate">
                <a :href="`#`" target="_blank" class="text-reset d-block">
                  {{ mask(lead.leadFirstName) }} {{ mask(lead.leadLastName) }} -
                  {{ mask(lead.leadEmailAddress) }}
                </a>
                <div class="d-block text-muted text-truncate mt-n1">
                  {{ mask(lead.leadTitle) }} @ {{ mask(lead.leadCompanyName) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="d-flex flex-column align-items-center p-4 w-100 h-100 text-center"
        v-else
      >
        <img
          src="@/assets/illustrations/undraw_Team.svg"
          class="mb-2"
          alt=""
          height="96"
        />
        <small class="text-muted">
          No leads yet. You'll get an email notification when this content
          generates a lead.
        </small>
      </div>

      <div
        v-if="false"
        class="position-absolute content-boost-badge bottom-0 bg-primary start-50 translate-middle z-top"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-trending-up"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <polyline points="3 17 9 11 13 15 21 7"></polyline>
          <polyline points="14 7 21 7 21 14"></polyline>
        </svg>

        Boost content
      </div>
    </div>
    <div class="card-footer d-flex flex-row">
      <span class="me-auto">Posted {{ niceDate(content.createDate) }}</span>

      <div class="d-flex flex-row align-content-center">
        <span class="me-3">
          <a
            :href="contentUrl"
            @click="trackPreview"
            title="Preview content"
            v-b-tooltip.hover
            target="_blank"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-external-link"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path
                d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5"
              ></path>
              <line x1="10" y1="14" x2="20" y2="4"></line>
              <polyline points="15 4 20 4 20 9"></polyline>
            </svg>
          </a>
        </span>

        <a
          href="#"
          @click.prevent="downloadLeads"
          v-b-tooltip.hover
          :title="`Download ${pluralLeads}`"
          v-if="$auth.planIncludes('leads', $auth.highestPlan)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-download"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2"></path>
            <polyline points="7 11 12 16 17 11"></polyline>
            <line x1="12" y1="4" x2="12" y2="16"></line>
          </svg>

          {{ leads.length }}
        </a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.middle {
  transform: translate(-50%, -50%) !important;
  left: 50% !important;
  top: 50% !important;
  z-index: 100;
}
.max-height-limit {
  max-height: 218px;
  overflow-y: auto;
}

.z-top {
  z-index: 1025;
}

.content-card {
  min-height: 340px;
}

.content-boost-badge {
  color: white;
  padding: 5px;
  border-radius: 4px 4px 0 0;
}

.hide {
  filter: blur(3px);
  user-select: none;
}
</style>

<script>
import { format } from "date-fns"; //isBefore  //parseISO format isBefore
import WhitePaperService from "../../../services/WhitePaperService";
import TrackingService from "../../../services/TrackingService";

import ContentTypeIcon from "./ContentTypeIcon.vue";

import CSVService from "../../../services/CSVService";
import UpsellService from "../../../services/UpsellService";

export default {
  name: "content.library.ContentCard",
  components: {
    ContentTypeIcon,
  },
  props: {
    content: Object,
    allLeads: Array,
    archive: Boolean,
    site: Object,
    contentRemaining: Object,
  },
  computed: {
    leads() {
      return this.allLeads.filter(
        (l) => l.leadSource == this.content.whitePaperID
      );
    },
    pluralLeads() {
      if (this.leads.length == 1) {
        return "lead";
      } else {
        return "leads";
      }
    },
    contentUrl(){
      return process.env.VUE_APP_STORAGE_CONTENT_URL + this.content.url
    }
  },
  methods: {
    trackPreview() {
      TrackingService.track("open content preview", {
        siteId: this.site.siteid,
        detail: this.content.paperTitle,
      });
    },
    slug(text) {
      const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
      const to = "aaaaaeeeeeiiiiooooouuuunc------";

      const newText = text
        .split("")
        .map((letter, i) =>
          letter.replace(new RegExp(from.charAt(i), "g"), to.charAt(i))
        );

      return newText
        .toString() // Cast to string
        .toLowerCase() // Convert the string to lowercase letters
        .trim() // Remove whitespace from both sides of a string
        .replace(/\s+/g, "-") // Replace spaces with -
        .replace(/&/g, "-and-") // Replace & with 'and'
        .replace(/[^\w-]+/g, "") // Remove all non-word chars
        .replace(/--+/g, "-"); // Replace multiple - with single -
    },
    async downloadLeads() {
      TrackingService.track("download content leads", {
        siteId: this.site.siteid,
        detail: this.content.paperTitle,
      });

      let fields = [
        {
          id: "FirstName",
          getter: (r) => r["leadFirstName"],
        },
        {
          id: "LastName",
          getter: (r) => r["leadLastName"],
        },
        {
          id: "Title",
          getter: (r) => r["leadTitle"],
        },
        {
          id: "Company",
          getter: (r) => r["leadCompanyName"],
        },
        {
          id: "EmailAddress",
          getter: (r) => r["leadEmailAddress"],
        },
      ];
      let csv = CSVService.serialize({
        fields: fields,
        records: this.leads,
      });
      let download = document.createElement("a");
      let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      let url = URL.createObjectURL(blob);
      download.href = url;
      download.setAttribute(
        "download",
        `${this.slug(this.content.paperTitle)}-leads.csv`
      );
      download.click();
    },
    niceDate(date) {
      return format(date, "LLL dd, yyyy");
    },
    async doArchival() {
      let confirm = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to archive "${this.content.paperTitle}" from your listing on the ${this.site.info.sitename}? You can unarchive at any time.`,
        {
          title: `Archive content?`,
          size: "sm",
          okVariant: "outline-danger",
          okTitle: "Archive",
          cancelClasses: "me-auto",
          cancelTitle: "Cancel",
          cancelVariant: "outline",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        }
      );

      if (confirm) {
        await WhitePaperService.archiveWhitepaperSite(
          this.$auth.activeCompany.companyId,
          this.site.siteID,
          { whitepaperid: this.content.whitePaperID }
        );
        this.$emit("refresh");
      }
    },
    async doUnarchival() {
      if (this.contentRemaining[`${this.site.siteID}`] < 1) {
        this.$emit("unarchive", this.content);
        return;
      }
      await WhitePaperService.archiveWhitepaperSite(
        this.$auth.activeCompany.companyId,
        this.site.siteID,
        { whitepaperid: this.content.whitePaperID }
      );
      this.$emit("refresh");
    },
    mask(lead) {
      if (!lead) return "";
      return UpsellService.domainToMasked(lead);
    },
  },
};
</script>
