<template>
  <div class="home page-wrapper">
    <div class="container-xl">
      <!-- Page title -->
      <div class="page-header d-print-none">
        <div class="row align-items-center">
          <div class="col">
            <!-- Page pre-title -->
            <div class="page-pretitle">
              {{ $auth.activeCompany.companyName }}
            </div>
            <h2 class="page-title">Content Library</h2>
          </div>
          <div class="col">
            <site-select v-model="site"></site-select>
          </div>
          <div class="col-12 col-md-auto ms-auto d-print-none">
            <div class="btn-list">
              <router-link
                :to="`/content/edit`"
                class="btn btn-primary d-none d-sm-inline-block"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-plus"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <line x1="12" y1="5" x2="12" y2="19"></line>
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                </svg>

                Add New Content
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-body">
      <div class="container-xl" v-if="loaded">
        <div class="row" v-if="liveContent.length > 0">
          <div class="col">
            <h3>Live content on this site</h3>
          </div>
        </div>
        <div class="row row-deck row-cards mb-3" v-if="liveContent.length > 0">
          <div
            class="col-lg-4 col-md-6"
            v-for="c in liveContent"
            :key="`content-${c.whitePaperID}`"
          >
            <content-card
              :content="c"
              :archive="true"
              :allLeads="displayLeadsDTOs"
              :contentRemaining="contentRemaining"
              :site="site"
              @refresh="refresh"
            ></content-card>
          </div>
        </div>
        <div
          class="d-flex flex-column align-items-center justify-content-center"
          v-else
        >
          <div class="d-flex flex-column limit-width text-center">
            <img
              src="@/assets/illustrations/undraw_Add_files.svg"
              class="mb-2"
              alt=""
              height="128"
            />
            <h3>Share content with potential buyers</h3>
            <p>
              You haven't added any content yet. Share marketing content to
              generate leads and give prospects more information about your
              company.
            </p>
            <router-link class="btn btn-primary" :to="`/content/edit`">
              Add Content
            </router-link>
          </div>
        </div>
        <div class="row" v-if="archivedContent.length > 0">
          <div class="col">
            <h3>Archived content on this site</h3>
          </div>
        </div>
        <div class="row row-deck row-cards">
          <div
            class="col-lg-4 col-md-6"
            v-for="c in archivedContent"
            :key="`content-${c.whitePaperID}`"
          >
            <content-card
              :content="c"
              :archive="false"
              :allLeads="leadsDTOs"
              :contentRemaining="contentRemaining"
              :site="site"
              @refresh="refresh"
              @unarchive="unarchiveEvent"
            ></content-card>
          </div>
        </div>
      </div>
      <div class="container-xl" v-else>
        <div class="row">
          <div class="col">
            <h3>Loading white papers...</h3>
          </div>
        </div>
      </div>
    </div>
    <archive-content-modal
      v-if="site && site.info"
      :site="site.info"
      :remove="contentRemaining[`${this.site.info.siteid}`]"
      @archive="archiveAndRefresh"
    ></archive-content-modal>
  </div>
</template>

<style lang="scss">
.table .text-right {
  text-align: right;
}
.table .text-left {
  text-align: left;
}
.table .text-center {
  text-align: center;
}
.table-responsive {
  margin-bottom: 0px;
}
.alert {
  padding-top: 0.4375rem;
  padding-right: 1rem;
  padding-bottom: 0.4375rem;
  padding-left: 1rem;
}
.shortdescription {
  width: 470px;
  height: 80px;
  resize: none;
}
.sitecatdivider {
  padding: 0px;
  margin: 0px;
  margin-bottom: 10px;
}

.limit-width {
  max-width: 360px;
}
</style>

<script>
import WhitePaperService from "../../services/WhitePaperService";
import TrackingService from "../../services/TrackingService";
import LeadService from "../../services/LeadService";
import { format, parseISO, compareDesc } from "date-fns"; //isBefore  //parseISO format isBefore
import ContentCard from "../../components/Content/Library/ContentCard.vue";
import SiteSelect from "../../components/Content/Library/SiteSelect.vue";
import ArchiveContentModal from "../../components/Content/Utility/ArchiveContentModal.vue";

export default {
  components: { ContentCard, SiteSelect, ArchiveContentModal },
  name: "WhitePaper",
  data: () => {
    return {
      content: [],
      leads: [],
      leadsDTOs: [],
      site: null,
      siteContent: null,
      loaded: false,
      contentRemaining: {},
      unarchive: null,
    };
  },
  watch: {
    async site() {
      let content = await this.getSiteWhitePapers(this.site);
      this.siteContent = content;
      await this.getContentRemaining();
    },
  },
  computed: {
    liveContent() {
      if (!this.siteContent) {
        return [];
      }
      return this.content.filter((c) => {
        let contentId = c.whitePaperID;
        return (
          this.siteContent.live.filter((lc) => lc.whitePaperId == contentId)
            .length > 0
        );
      });
    },
    archivedContent() {
      if (!this.siteContent) {
        return [];
      }
      return this.content.filter((c) => {
        let contentId = c.whitePaperID;
        return (
          this.siteContent.archived.filter((lc) => lc.whitePaperId == contentId)
            .length > 0
        );
      });
    },
    filteredLeadsDTOs() {
      return this.leadsDTOs.filter((n) => {
        if (!this.site) return true;
        return this.site.siteID == n.siteId;
      });
    },
    displayLeadsDTOs() {
      return this.filteredLeadsDTOs;
    },
  },
  methods: {
    unarchiveEvent(content) {
      this.unarchive = {
        whitepaperid: content.whitePaperID,
      };
      this.$bvModal.show(`archive-content-modal-${this.site.info.siteid}`);
    },
    niceDate(date) {
      return format(date, "LLL dd, yyyy");
    },
    async archiveAndRefresh(content) {
      let toArchive = content;
      let site = this.site.info;
      for (let i = 0; i < toArchive.length; i++) {
        await WhitePaperService.archiveWhitepaperSite(
          this.$auth.activeCompany.companyId,
          site.siteid,
          toArchive[i]
        );
        TrackingService.track("archived content", {
          group: "Content Library",
          type: "Deep Product Interaction",
          siteId: site.siteid,
          detail: toArchive[i],
        });
      }
      if (this.unarchive) {
        await WhitePaperService.archiveWhitepaperSite(
          this.$auth.activeCompany.companyId,
          site.siteid,
          this.unarchive
        );

        TrackingService.track("unarchived content", {
          siteId: site.siteid,
          detail: this.unarchive,
        });
        this.unarchive = null;
      }
      await this.refresh();
    },
    async getContentRemaining() {
      this.contentRemaining = await WhitePaperService.getContentRemaining(
        this.$auth.activeCompany.companyId
      );
    },
    async getSiteWhitePapers(site) {
      let company = this.$auth.activeCompany;
      let siteContent = await WhitePaperService.getWhitepapersForSite(
        company.companyId,
        site.siteID
      );
      return siteContent;
    },
    async getLeads() {
      let filter = {
        company: this.$auth.activeCompany,
      };

      // make service call
      let leadsDTOs = await LeadService.getContentLeads(filter);

      // sort by siteId
      this.leadsDTOs = leadsDTOs.sort((a) => a.siteId);
    },
    async getWhitePapers() {
      let company = this.$auth.activeCompany;

      let whitePaperFilter = {
        Company: { Companyid: company.companyId },
      };

      // make service call
      let whitePaperDTOs = await WhitePaperService.getWhitePapers(
        whitePaperFilter
      );

      // iterate through each row (start at 1)
      for (var i = 0; i < whitePaperDTOs.length; i++) {
        whitePaperDTOs[i].createDate = parseISO(whitePaperDTOs[i].createDate);
        whitePaperDTOs[i].modifyDate = parseISO(whitePaperDTOs[i].modifyDate);
        whitePaperDTOs[i].rowid = i + 1;
      }

      // sort by sitename
      this.content.splice(0);

      this.content = whitePaperDTOs.sort((a, b) =>
        compareDesc(a.createDate, b.createDate)
      );
    },
    async refresh() {
      await this.getWhitePapers();
      await this.getLeads();
      this.siteContent = await this.getSiteWhitePapers(this.site);
      await this.getContentRemaining();
    },
  },
  async mounted() {
    // load white paper data for company
    await this.getWhitePapers();
    await this.getLeads();
    await this.getContentRemaining();
    this.loaded = true;

    TrackingService.track("viewed content library");
  },
};
</script>
